var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"md:ml-12 mb-8 mr-4 md:mr-0"},[(_vm.isLoading)?_c('trac-loading'):_vm._e(),_c('trac-validation-observer',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.saveProfile)}}},[_c('div',{staticClass:"wrapper mb-8"},[_c('ul',{staticClass:"flex-col mt-12"},[_c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v("Settings")])]),_c('li',{staticClass:"font-bold mt-4 text-2xl"},[_vm._v("Company Information")])])]),_c('div',{staticClass:"px-8 py-8 bg-white h-full rounded-lg overflow-hidden big-shadow"},[_c('div',{staticClass:"grid md:flex flex-row md:divide-x"},[_c('div',{staticClass:"mb-10 md:mb-0 md:w-3/12 mr-5 px-10"},[(_vm.business.logo || _vm.imageToUpload.src)?_c('div',{staticClass:"w-full h-auto relative"},[_c('img',{staticClass:"h-40 w-full",attrs:{"src":_vm.imageToUpload.src || _vm.business.logo,"alt":""}}),_c('input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"absolute bottom-0",attrs:{"accept":"image/png, image/jpeg, image/jpg","type":"file","name":"","id":"product-image"},on:{"change":_vm.selectImage}}),_c('button',{staticClass:"w-full p-2 text-sm bg-primaryGray text-white",on:{"click":_vm.removeImage}},[_vm._v(" Remove Image ")])]):_c('div',{staticClass:"flex-wrap flex-column w-2/12"},[_c('div',{staticClass:"flex w-48 h-40 no-img-bg relative"},[_c('input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"absolute bottom-0",attrs:{"accept":"image/png, image/jpeg, image/jpg","type":"file","name":"","id":"product-image"},on:{"change":_vm.selectImage}}),_c('div',{staticClass:"flex w-48 cursor-pointer h-10 bg-primaryGray mt-auto text-white justify-center items-center font-medium text-sm",on:{"click":_vm.triggerFileOpen}},[_vm._v(" Upload Image ")])])])]),_c('div',{staticClass:"flex-column md:w-9/12 md:px-10"},[_c('div',{staticClass:"grid md:grid-cols-2 gap-10"},[_c('trac-validation-provider',{attrs:{"name":"company name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('trac-input',{attrs:{"placeholder":"Company Name"},model:{value:(_vm.business.name),callback:function ($$v) {_vm.$set(_vm.business, "name", $$v)},expression:"business.name"}}),(errors[0])?_c('trac-input-error',[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_c('trac-validation-provider',{attrs:{"name":"street","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('trac-input',{attrs:{"placeholder":"Street"},model:{value:(_vm.business.address.street),callback:function ($$v) {_vm.$set(_vm.business.address, "street", $$v)},expression:"business.address.street"}}),(errors[0])?_c('trac-input-error',[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"grid md:grid-cols-2 gap-10 mt-12"},[_c('trac-validation-provider',{attrs:{"name":"city","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('trac-input',{attrs:{"placeholder":"City"},model:{value:(_vm.business.address.city),callback:function ($$v) {_vm.$set(_vm.business.address, "city", $$v)},expression:"business.address.city"}}),(errors[0])?_c('trac-input-error',[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_c('trac-validation-provider',{attrs:{"name":"state","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('trac-input',{attrs:{"placeholder":"State"},model:{value:(_vm.business.address.state),callback:function ($$v) {_vm.$set(_vm.business.address, "state", $$v)},expression:"business.address.state"}}),(errors[0])?_c('trac-input-error',[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"grid md:grid-cols-2 gap-10 mt-12"},[_c('trac-validation-provider',{attrs:{"name":"country","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('trac-input',{attrs:{"placeholder":"Country"},model:{value:(_vm.business.address.country),callback:function ($$v) {_vm.$set(_vm.business.address, "country", $$v)},expression:"business.address.country"}}),(errors[0])?_c('trac-input-error',[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_c('trac-validation-provider',{attrs:{"name":"phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('trac-input',{attrs:{"placeholder":"Phone Number"},model:{value:(_vm.business.phone),callback:function ($$v) {_vm.$set(_vm.business, "phone", $$v)},expression:"business.phone"}}),(errors[0])?_c('trac-input-error',[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"grid md:grid-cols-2 gap-10 mt-12"},[_c('trac-input',{attrs:{"disabled":"","placeholder":"Email"},model:{value:(_vm.business.email),callback:function ($$v) {_vm.$set(_vm.business, "email", $$v)},expression:"business.email"}})],1)])])]),_c('div',{staticClass:"flex mt-5 justify-end"},[_c('trac-button',{staticClass:"uppercase",attrs:{"type":"submit"}},[_vm._v("Save changes")])],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }